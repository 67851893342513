import {UserCreate, UserEdit, UserList} from '../components/User';
import {RoleList} from '../components/Role';

export const API_PLATFORM_PROVIDER = {
  CURRENCIES: 'currencies',
  IMAGES: 'images',
  PREFERENCES: 'user_preferences',
  ROLES: 'roles',
  USERS: 'users',
  DOCUMENTS: 'documents',
};

export const apiPlatformNotIndexedResources = [
  API_PLATFORM_PROVIDER.CURRENCIES,
  API_PLATFORM_PROVIDER.IMAGES,
];

export const apiPlatformIndexedResources = [
  {
    name: API_PLATFORM_PROVIDER.USERS,
    options: {label: 'Users'},
    list: UserList,
    create: UserCreate,
    edit: UserEdit,
  },
  {
    name: API_PLATFORM_PROVIDER.ROLES,
    options: {label: 'Roles'},
    list: RoleList,
  },
];

export const apiPlatformResources = [
  ...apiPlatformIndexedResources,
  ...apiPlatformNotIndexedResources.map(i => ({name: i})),
];
