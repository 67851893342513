import {APIPlatformSerializer} from './abstract';
import {PROVIDER} from '../../../resources';

export class DocumentSerializer extends APIPlatformSerializer {
  base = PROVIDER.DOCUMENTS;

  serialize(data) {
    this.filename = data.filename;
    this.type = data.type;
    this.companyId = data.company?.id || data.company;
    this.attachments = data.attachments || '';
  }
}
