import {APIPlatformSerializer} from './abstract';
import {CurrencySerializer} from './CurrencySerializer';
import {RoleSerializer} from './RoleSerializer';
import {Preference} from '../providers/Preference';
import {Container} from '../../bcm/providers/Container';
import {PreferenceSerializer} from './PreferenceSerializer';

export class UserSerializer extends APIPlatformSerializer {
  base = '/users';
  company;
  organization;
  password;
  roles;
  containers;
  username;
  preference;
  tooManyPasswordAttempt;
  lastConnexionDate;

  serialize(user) {
    // Use this only with the unlock button
    if (user.tooManyPasswordAttempt !== undefined && Object.keys(user).length === 2) {
      this.tooManyPasswordAttempt = user.tooManyPasswordAttempt;
      return;
    }

    if (user.preference && user.preference.id) {
      new Preference().update({
        data: {
          id: user.preference.id,
          currency: new CurrencySerializer().getIRI(user.preference.currency.id),
          language: user.preference.language ?? 'en-US',
        },
      });
      this.preference = new PreferenceSerializer().getIRI(user.preference.id);
    } else {
      this.preference = {
        currency: new CurrencySerializer().getIRI(user.preference.currency.id),
        language: user.preference.language ?? 'en-US',
      };
    }
    this.company = user.company;
    this.organization = user.organization;
    this.password = user.password;
    this.lastConnexionDate = user.lastConnexionDate;
    this.roles = user.rolesIds?.map(role => new RoleSerializer().getIRI(role)) || [];
    this.username = user.username;

    const allRoles = JSON.parse(localStorage.getItem('roles'));
    const isExternalOperator = allRoles?.find(
      r => r.name === 'ROLE_EXTERNAL_OPERATOR' && user.rolesIds?.includes(r.id)
    );
    const isInternalOperator = allRoles?.find(
      r => r.name === 'ROLE_INTERNAL_OPERATOR' && user.rolesIds?.includes(r.id)
    );
    if (isExternalOperator || isInternalOperator) {
      if (user.id) {
        const initContainers = user.containers ? user.containers.map(uc => uc.id) : [];
        const idsToAdd = user.containersIds.filter(c => !initContainers.includes(c));

        const provider = new Container();
        if (idsToAdd.length > 0) {
          provider
            .request()
            .post(provider.getUrl(`/operators`), {
              idContainers: idsToAdd,
              IdOperators: [user.id],
              TypeOperator: isInternalOperator ? 1 : 0,
            })
            .then(() => {})
            .catch(err => {
              console.error(err);
            });
        }
        const idsToDelete = initContainers.filter(c => !user.containersIds.includes(c));
        if (idsToDelete.length > 0) {
          provider
            .request()
            .delete(provider.getUrl(`/operators`), {
              data: {
                idContainers: idsToDelete,
                IdOperators: [user.id],
                TypeOperator: isInternalOperator ? 1 : 0,
              },
            })
            .then(() => {})
            .catch(err => {
              console.error(err);
            });
        }
      } else {
        this.containers = user.containersIds;
      }
    }
  }
}
