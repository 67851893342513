import React from 'react';
import {
  AutocompleteArrayInput,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  regex,
  SelectArrayInput,
  SelectInput,
  TextInput,
  FileInput,
  FileField,
} from 'react-admin';
import {FieldAutocomplete, FilteredSelect} from './Overrides/Inputs';
import {ArrayList} from './Custom/List';
export const AUTOCOMPLETE_INPUT = 'autocompleteInput';
export const AUTOCOMPLETE_ARRAY_INPUT = 'autocompleteArrayInput';
export const BOOLEAN = 'boolean';
export const CHOICES = 'choices';
export const DATETIME = 'datetime';
export const FORMDATA = 'formdata';
export const NUMBER = 'number';
export const FILE = 'file';
export const REFERENCE_ARRAY_INPUT = 'referenceArrayInput';
export const REFERENCE_INPUT = 'referenceInput';
export const ARRAYLIST = 'arrayList';

let oldSelectedUsersCompany = undefined;
let oldSelectedContainerCompany = undefined;

const resetInputsCompany = formData => {
  if (
    formData.company?.id &&
    oldSelectedUsersCompany !== formData.company.id &&
    (formData.usersIds || formData.operatorsIds)
  ) {
    formData.usersIds = [];
    formData.operatorsIds = [];
  }
  oldSelectedUsersCompany = formData.company?.id;
  if (oldSelectedContainerCompany !== formData.company && formData.containersIds) {
    formData.containersIds = [];
  }
  oldSelectedContainerCompany = formData.company;
};

export const InputGuesser = ({children, type, ...rest}) => {
  switch (type) {
    case AUTOCOMPLETE_INPUT: {
      return (
        <ReferenceInput {...rest} options={!rest.classes && {fullWidth: true}}>
          <FieldAutocomplete {...children} options={!rest.classes && {fullWidth: true}} />
        </ReferenceInput>
      );
    }
    case AUTOCOMPLETE_ARRAY_INPUT: {
      return (
        <ReferenceArrayInput {...rest} options={{fullWidthInput: true}}>
          <AutocompleteArrayInput {...children} />
        </ReferenceArrayInput>
      );
    }
    case BOOLEAN: {
      return (
        <BooleanInput
          {...{
            ...rest,
            options: {
              fullWidth: true,
              ...rest.options,
            },
          }}
        />
      );
    }
    case CHOICES: {
      return (
        <SelectInput
          {...{
            ...rest,
            options: {
              fullWidth: true,
              ...rest.options,
            },
          }}
        />
      );
    }
    case DATETIME: {
      return (
        <DateInput
          {...{
            ...rest,
            options: {
              fullWidth: true,
              ...rest.options,
            },
          }}
          validate={regex(
            /^((19|20)[0-9]{2})-((0?[0-9])|(1[0-2]))-((0?[0-9])|([1-2][0-9])|(3[0-1]))$/,
            'Must match YYYY-MM-DD'
          )}
        />
      );
    }
    case FORMDATA: {
      return (
        <FormDataConsumer>
          {({formData}) => {
            if (formData && formData.company) resetInputsCompany(formData);

            if (rest.renderFormData) {
              const Tag = rest.renderFormData;
              return <Tag {...formData} />;
            }
            return (
              <InputGuesser
                {...{
                  ...rest,
                  options: {
                    fullWidth: true,
                    ...rest.options,
                  },
                  children: {
                    ...children,
                  },
                  filter: rest.filter(formData),
                  type: children.type,
                }}
              />
            );
          }}
        </FormDataConsumer>
      );
    }
    case NUMBER: {
      return (
        <NumberInput
          {...{
            ...rest,
            options: {
              fullWidth: true,
              ...rest.options,
            },
          }}
        />
      );
    }
    case REFERENCE_ARRAY_INPUT: {
      return (
        <ReferenceArrayInput
          {...{
            ...rest,
            allowEmpty: true,
            options: {
              fullWidth: true,
              ...rest.options,
            },
            perPage: 1000,
          }}
        >
          <SelectArrayInput {...children} fullWidth allowEmpty />
        </ReferenceArrayInput>
      );
    }
    case REFERENCE_INPUT: {
      return (
        <ReferenceInput
          {...{
            ...rest,
            allowEmpty: true,
            choices: null,
            options: {
              fullWidth: true,
              ...rest.options,
            },
            perPage: 1000,
          }}
        >
          <FilteredSelect {...{...rest, children}} fullWidth allowEmpty />
        </ReferenceInput>
      );
    }
    case ARRAYLIST: {
      return <ArrayList items={{...rest}} fullWidth />;
    }
    case FILE: {
      return (
        <FileInput source="attachments">
          <FileField source="src" title="title" />
        </FileInput>
      );
    }
    default: {
      return (
        <TextInput
          {...{
            ...rest,
            type,
          }}
          fullWidth
        />
      );
    }
  }
};
