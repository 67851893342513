import axios from 'axios';
import {Token} from '../../helpers';
import {AbstractSerializer} from './Serializer';
import {
  authenticationTokenUri,
  authProvider,
  REFRESH_TOKEN,
  TOKEN,
} from '../../authProvider';

class RefreshTokenSingleton {
  static refresh() {
    return fetch(
      new Request(authenticationTokenUri, {
        method: 'POST',
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem(REFRESH_TOKEN),
          client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
        }).toString(),
        headers: new Headers({'Content-Type': 'application/x-www-form-urlencoded'}),
      })
    )
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(({access_token, refresh_token}) => {
        localStorage.setItem(TOKEN, access_token); // The JWT token is stored in the browser's local storage
        localStorage.setItem(REFRESH_TOKEN, refresh_token);
      })
      .catch(err => {
        console.error('Error getting refresh token', err);
        authProvider.logout();
      });
  }
}

export class AbstractProvider {
  endpoint = '';
  env = process.env.REACT_APP_ENV || 'int';
  filter = {};
  ids = [];
  management = '';
  serializer = new AbstractSerializer();
  model = null;
  name = '';
  pagination = {};

  constructor({filter, ids, pagination} = {}) {
    this.filter = filter;
    this.ids = ids;
    this.pagination = pagination;
  }

  throwError = e => {
    if (e.response?.status === 401) {
      authProvider.logout();
      return;
    }

    throw new Error(
      e.response?.data['hydra:description'] ||
        e.response?.data?.message ||
        e.response?.data?.description ||
        e
    );
  };

  getHeaders = () => {
    const h = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    if (new Token().get()) {
      h['Authorization'] = `Bearer ${new Token().get()}`;
    }

    return h;
  };

  request() {
    const instance = axios.create({
      headers: this.getHeaders(),
    });
    instance.interceptors.request.use(
      async config => {
        const token = new Token();

        if (config.url !== '/login') {
          if (token.get() && token.getDecoded().exp - Date.now() / 1000 <= 0) {
            await RefreshTokenSingleton.refresh();
          }
        }
        return config;
      },
      error => Promise.reject(error)
    );
    return instance;
  }

  getUrl = () => '';

  create = ({data}) =>
    this.request()
      .post(this.getUrl(), this.serializer.create(data))
      .then(response => ({data: this.model.getOne(response.data)}))
      .catch(this.throwError);

  delete = ({id}) =>
    this.request()
      .delete(this.getUrl(`/${id}`))
      .then(() => ({data: {id}}))
      .catch(this.throwError);

  deleteMany = () => {
    const axiosInstance = this.request();
    return Promise.all(this.ids.map(id => axiosInstance.delete(this.getUrl(`/${id}`))))
      .then(() => ({data: this.ids}))
      .catch(this.throwError);
  };

  getList = () =>
    this.request()
      .get(this.getUrl())
      .then(({data}) => ({
        data: this.model.getMany(
          data.data ? data.data : data['hydra:member'] ? data['hydra:member'] : data
        ),
        total:
          data['hydra:totalItems'] ||
          data.count ||
          (data.data && data.data.length) ||
          data.length ||
          0,
      }))
      .catch(this.throwError);

  update = ({data}) =>
    this.request()
      .put(this.getUrl(`/${data.id}`), this.serializer.update(data))
      .then(({data}) => ({
        data: this.model.getOne(data),
      }))
      .catch(this.throwError);

  getOne = ({id}) =>
    this.request()
      .get(this.getUrl(`/${id}`))
      .then(({data}) => ({
        data: this.model.getOne(data),
      }))
      .catch(this.throwError);

  getManyCommon = ({ids}) =>
    Promise.all(
      ids.map(item =>
        this.request()
          .get(this.getUrl(`/${item.id || item}`))
          .then(({data}) => this.model.getOne(data))
      )
    );

  getMany = params =>
    this.getManyCommon(params)
      .then(data => ({data}))
      .catch(this.throwError);

  getManyReference = params =>
    this.getManyCommon({ids: [params.id]})
      .then(data => ({data, total: data.length}))
      .catch(this.throwError);
}
