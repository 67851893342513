import {
  AUTOCOMPLETE_INPUT,
  NUMBER,
  DATETIME,
  FUNCTION_FIELD,
  REFERENCE_FIELD,
  REFERENCE_INPUT,
} from '../Common';
import {PROVIDER} from '../../resources/bcmApi';
import {required} from 'react-admin';

export const getFields = () => [
  {
    children: {source: 'name'},
    label: 'Contract',
    link: 'show',
    reference: PROVIDER.CONTRACTS,
    source: 'contract.id',
    type: REFERENCE_FIELD,
  },
  {
    children: {source: 'name'},
    label: 'Material Index Source',
    link: false,
    reference: PROVIDER.MATERIAL_INDEX_SOURCES,
    source: 'materialIndexSource.id',
    type: REFERENCE_FIELD,
  },
  {
    children: {source: 'fullname'},
    label: 'Material',
    link: 'show',
    reference: PROVIDER.MATERIALS,
    source: 'material.id',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Period',
    render: record => {
      const parts = [];
      if (record.period.start) {
        parts.push(new Date(record.period.start).toLocaleDateString());
      }
      if (record.period.end) {
        parts.push(' - ');
        parts.push(new Date(record.period.end).toLocaleDateString());
      }
      return parts.join('');
    },
    type: FUNCTION_FIELD,
  },
  {source: 'offset'},
];

export const getInputs = () => [
  {
    children: {
      optionText: 'name',
    },
    label: 'Contract',
    reference: PROVIDER.CONTRACTS,
    source: 'contract.id',
    type: REFERENCE_INPUT,
    validate: [required()],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Material Index Source',
    reference: PROVIDER.MATERIAL_INDEX_SOURCES,
    source: 'materialIndexSource.id',
    type: REFERENCE_INPUT,
    validate: [required()],
  },
  {
    children: {
      optionText: 'fullname',
    },
    label: 'Material',
    reference: PROVIDER.MATERIALS,
    source: 'material.id',
    type: AUTOCOMPLETE_INPUT,
    validate: [required()],
  },
  {
    initialValues: new Date().toISOString().split('T')[0],
    label: 'Start date',
    source: 'period.start',
    type: DATETIME,
  },
  {
    initialValues: new Date().toISOString().split('T')[0],
    label: 'End date',
    source: 'period.end',
    type: DATETIME,
  },
  {
    source: 'offset',
    type: NUMBER,
  },
];
