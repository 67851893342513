import {PROVIDER} from '../../resources';
import {AUTOCOMPLETE_INPUT, CHOICES, FORMDATA, REFERENCE_INPUT} from '../Common';
import {
  email,
  required,
  ReferenceArrayInput,
  SelectArrayInput,
  useDataProvider,
} from 'react-admin';
import React, {useEffect, useState} from 'react';

const companyValidator = (v, a) =>
  !v && !a.organization
    ? "The company is required if you don't select an organization"
    : null;

const organizationValidator = (v, a) =>
  !v && !a.company ? "The organization is required if you don't select a company" : null;

let roles;
let loadingRoles = true;

const getContainersUser = ({company, rolesIds}) => {
  const [isOperator, setIsOperator] = useState(false);
  const dataProvider = useDataProvider();
  useEffect(() => {
    Promise.resolve(
      loadingRoles &&
        dataProvider
          .getList('roles', {
            pagination: {page: 1, perPage: 1000},
          })
          .then(data => {
            loadingRoles = false;
            roles = data.data;
            localStorage.setItem('roles', JSON.stringify(roles));
          })
          .catch(err => {
            console.error(err);
          })
    ).then(() => {
      const isOperatorUser = roles?.find(
        r =>
          (r.name === 'ROLE_EXTERNAL_OPERATOR' || r.name === 'ROLE_INTERNAL_OPERATOR') &&
          rolesIds?.includes(r.id)
      );
      setIsOperator(isOperatorUser);
    });
  }, [rolesIds]);

  return isOperator ? (
    <ReferenceArrayInput
      {...{
        filter: company ? {idCompany: company} : {},
        label: 'Containers',
        source: 'containersIds',
        reference: PROVIDER.CONTAINERS,
        target: PROVIDER.CONTAINERS,
        type: FORMDATA,
        perPage: 1000,
        validate: [required()],
      }}
      options={{fullWidthInput: true}}
    >
      <SelectArrayInput {...{optionText: 'containerName'}} />
    </ReferenceArrayInput>
  ) : (
    <></>
  );
};

export const getInputs = () => [
  {
    label: 'Email',
    source: 'username',
    type: 'email',
    validate: [required(), email()],
  },
  {
    children: {
      children: {
        optionText: 'fullname',
      },
      type: AUTOCOMPLETE_INPUT,
    },
    allowEmpty: true,
    filter: d => ({companyLevel: (d && d.parentLevel) || 1}),
    label: 'Company',
    source: 'company',
    reference: PROVIDER.COMPANIES,
    type: FORMDATA,
    validate: [companyValidator],
  },
  {
    children: {
      optionText: 'name',
    },
    label: 'Organization',
    source: 'organization',
    reference: PROVIDER.ORGANIZATIONS,
    target: PROVIDER.ORGANIZATIONS,
    type: REFERENCE_INPUT,
    validate: [organizationValidator],
  },
  {
    renderFormData: getContainersUser,
    type: FORMDATA,
  },
  {
    label: 'Currency',
    name: 'preference.currency.id',
    source: 'preference.currency.id',
    reference: PROVIDER.CURRENCIES,
    target: PROVIDER.CURRENCIES,
    type: REFERENCE_INPUT,
    validate: [required()],
  },
  {
    choices: [
      {id: 'en-US', name: 'English'},
      {id: 'fr-FR', name: 'Français'},
    ],
    label: 'Language',
    name: 'preference.language',
    source: 'preference.language',
    type: CHOICES,
    validate: [required()],
  },
];
