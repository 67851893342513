import React from 'react';
import {CommonList} from '../Common';
import {PROVIDER} from '../../resources';
import {autoCompleteFilter} from '../Filters';
import {withStyles} from '@material-ui/core/styles';
import {getFields} from './Common';

export const DocumentList = withStyles({
  autocompleteInput: {fullWidth: true, margin: 300},
})(props => (
  <CommonList
    {...props}
    fields={getFields()}
    filters={[
      key =>
        autoCompleteFilter({
          alwaysOn: true,
          children: {
            optionText: 'name',
          },
          classes: props.classes.autocompleteInput,
          key,
          label: 'Company',
          name: PROVIDER.COMPANIES,
          reference: PROVIDER.COMPANIES,
          source: 'companyId',
        }),
    ]}
    bulkActionButtons={false}
  />
));
