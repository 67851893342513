import {Model, ModelInstance} from './Common';

class DocumentInstance extends ModelInstance {
  company = '';
  createdAt = '';
  constructor(document) {
    super(document);
    this.filename = document.filename;
    this.company = document.companyId || '';
    this.type = document.type || '';
    this.createdAt = document.createdAt || '';

    if (this.createdAt === '') this.createdAt = null;
  }
}

export class Document extends Model {
  getMany = documents => documents.map(document => new DocumentInstance(document));
  getOne = document => new DocumentInstance(document);
}
