import {PROVIDER} from '../../resources';
import {AUTOCOMPLETE_INPUT, FORMDATA, REFERENCE_FIELD, FILE} from '../Common';
import {required} from 'react-admin';

export const getFields = () => [
  {
    label: 'Name',
    source: 'filename',
  },
  {
    children: {source: 'fullname'},
    label: 'Company',
    link: 'show',
    reference: PROVIDER.COMPANIES,
    source: 'company',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Type',
    source: 'type',
  },
  {
    label: 'Publication date',
    source: 'createdAt',
  },
];

export const getInputs = () => [
  {
    label: 'Name',
    source: 'filename',
    inputProps: {maxLength: 128},
    validate: [required('The file name is mandatory')],
  },
  {
    label: 'Type',
    source: 'type',
    defaultValue: 'BRM',
    disabled: true,
    validate: [required('The file type is mandatory')],
  },
  {
    label: 'File',
    source: 'file',
    type: FILE,
    validate: [required('The file is mandatory')],
  },
  {
    children: {
      children: {
        optionText: 'fullname',
      },
      type: AUTOCOMPLETE_INPUT,
    },
    allowEmpty: true,
    filter: d => ({companyLevel: (d && d.parentLevel) || 1}),
    label: 'Company',
    source: 'company',
    reference: PROVIDER.COMPANIES,
    type: FORMDATA,
    validate: [required('The file company is mandatory')],
  },
];
