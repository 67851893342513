import {
  AUTOCOMPLETE_INPUT,
  DATETIME,
  FUNCTION_FIELD,
  NUMBER,
  REFERENCE_FIELD,
} from '../Common';
import {PROVIDER} from '../../resources/bcmApi';
import {required} from 'react-admin';

export const getFields = () => [
  {
    label: 'Source index',
    source: 'source.name',
  },
  {
    children: {source: 'fullname'},
    label: 'Material',
    link: 'show',
    reference: PROVIDER.MATERIALS,
    source: 'material.id',
    type: REFERENCE_FIELD,
  },
  {
    label: 'Index value',
    source: 'unitValue',
  },
  {
    label: 'Currency',
    source: 'source.currency',
  },
  {
    label: 'Period',
    render: record =>
      `${new Date(record.period.start).toLocaleDateString()} - ${new Date(
        record.period.end
      ).toLocaleDateString()}`,
    type: FUNCTION_FIELD,
  },
];

export const getInputs = () => [
  {
    children: {
      optionText: 'name',
    },
    label: 'Source index',
    reference: PROVIDER.MATERIAL_INDEX_SOURCES,
    source: 'source.id',
    type: AUTOCOMPLETE_INPUT,
    validate: [required()],
  },
  {
    children: {
      optionText: 'fullname',
    },
    label: 'Material',
    reference: PROVIDER.MATERIALS,
    source: 'material.id',
    type: AUTOCOMPLETE_INPUT,
    validate: [required()],
  },
  {
    label: 'Index value',
    source: 'unitValue',
    type: NUMBER,
  },
  {
    initialValues: new Date().toISOString().split('T')[0],
    label: 'Start date',
    source: 'period.start',
    type: DATETIME,
  },
  {
    initialValues: new Date().toISOString().split('T')[0],
    label: 'End date',
    source: 'period.end',
    type: DATETIME,
  },
];
